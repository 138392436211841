import { isDefined } from "@clipboard-health/util-ts";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { populateSessionAfterLogin } from "@src/app/appInit/populateSessionAfterLogin";
import { useSendOnboardingCheckrInvitation } from "@src/appV2/Accounts/Documents/api/useSendOnboardingCheckrInvitation";
import { CheckrPackageType } from "@src/appV2/Accounts/Documents/types";
import { useToast } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { USER_EVENTS } from "@src/constants";
import { useQueryClient } from "@tanstack/react-query";
import { mail } from "ionicons/icons";
import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Alert from "./Alert";
import { completeSignup, showCheckrInviteLink } from "./api";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { DISMISSED_STRIPE_POPUP, WAS_APP_PAUSED } from "../../onboardingStripe/constants";
import { AdjustEvents, trackAdjustEvent } from "../../utils/adjust";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { OnBoardingRouteProps } from "../model";
import { logOnboardingError } from "../util/logging";
import { fireOnboardingSegmentEvent } from "../util/segment";

const WelcomeAgent: FC<OnBoardingRouteProps> = ({ agent }) => {
  const [gettingData, setGettingData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });
  const {
    mutateAsync: sendCheckrInvitation,
    isLoading: isSendingCheckrInvitation,
    data: checkrInvitationResponse,
    error: checkrInvitationError,
    isError: isCheckrInvitationError,
  } = useSendOnboardingCheckrInvitation();
  const isInvitationSent =
    agent?.backgroundCheckApplications?.some(
      (bcApplication) => bcApplication?.applicationType === "checkr"
    ) || isDefined(checkrInvitationResponse?.id);
  const [showCheckrBCInvite, setShowCheckrBCInvite] = useState<boolean>(false);
  const history = useHistory();

  const { howToContactSupportLink } = useSupportLinks();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event && event.preventDefault();
    setErrorAlert({ hasError: false });
    try {
      setGettingData(true);
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_APP, {
        hcpId: agent?.userId as string,
        email: agent?.email as string,
      });
      trackAdjustEvent(AdjustEvents.ENTERED_APP, {
        hcpId: agent?.userId?.toString() ?? "",
      });
      await completeSignup();
      trackAdjustEvent(AdjustEvents.SIGNED_UP, {
        hcpId: agent?.userId?.toString() ?? "",
      });

      await queryClient.refetchQueries([GET_WORKER_PATH]);

      // Used to track if user is coming from signup page for stripe popup
      localStorage.removeItem(DISMISSED_STRIPE_POPUP);
      localStorage.removeItem(WAS_APP_PAUSED);

      /**
       * We need to call this again because the `userId` is not set in signup until this
       * point. When the user enter the OTP to sign-up, we are setting the `isAuthorized`
       * to true. When that happens, we don't set the Redux session state called `userId`,
       * which is needed in many places later (such as displaying open shifts). The below
       * call is needed to set the required properties, such as `userId`, in the Redux
       * session state.
       *
       * The ideal solution would be to either not rely on Redux, or to populate Redux
       * session's `userId` using the API response. But since that refactor is large, we
       * are doing it this way for now.
       *
       * There might also be a doubt about why populate at this onboarding step, and not
       * before (say) right after user enters the email and name. The reason is that this
       * step of onboarding is the last step of the signup process, and is guaranteed to
       * have the information, while the previous steps might be prone to flakiness.
       */
      await populateSessionAfterLogin(dispatch);

      history.push("/home/openShifts");
    } catch (error) {
      if (!error.response || !error.response.text || error.response.status >= 500) {
        logOnboardingError(ONBOARDING_STAGES.FINAL, (error as Error).message, agent?.userId);
        setErrorAlert({
          hasError: true,
          reason: error?.message,
        });
        return;
      }
      showErrorToast(error.response.text);
    } finally {
      setGettingData(false);
    }
  };

  const closeErrorModal = () => {
    setErrorAlert({ hasError: false });
  };

  const handleContactSupport = () => {
    if (!howToContactSupportLink) {
      return;
    }
    logEvent(USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
      articleLink: howToContactSupportLink,
      articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
      userId: agent?.userId,
      supportContext: SupportContext.ONBOARDING_CHECKR_ERROR,
    });
    window.open(howToContactSupportLink, "_blank");
  };

  const onCheckrInviteClick = async () => {
    // Ideally this should not happen in the production this is just to stop undefined complaints from typescript
    if (!agent?.email) {
      logError(USER_EVENTS.ONBOARDING_ERROR, {
        error: new Error(`Agent email not present for agent id: ${agent?.userId}`),
        metadata: {
          workerId: agent?.userId,
        },
      });
      return;
    }
    try {
      await sendCheckrInvitation({
        email: agent.email,
        packageType: CheckrPackageType.BASIC_CRIMINAL,
      });
    } catch (error) {
      if (!error.response || !error.response.text || error.response.status >= 500) {
        logOnboardingError(ONBOARDING_STAGES.CHECKR, (error as Error).message, agent?.userId);
        return;
      }
    }
  };

  const fetchCheckrInviteStatus = useCallback(async () => {
    try {
      const { showCheckrInvite } = await showCheckrInviteLink();
      setShowCheckrBCInvite(showCheckrInvite);
    } catch (error) {
      if (!error.response || !error.response.text || error.response.status >= 500) {
        logOnboardingError(ONBOARDING_STAGES.CHECKR, (error as Error).message, agent?.userId);
        return;
      }
    }
  }, [agent?.userId]);

  useEffect(() => {
    fetchCheckrInviteStatus();
  }, [fetchCheckrInviteStatus]);

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentLicense" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <Alert
          isOpen={errorAlert?.hasError}
          reason={errorAlert.reason}
          closeAlert={closeErrorModal}
        />

        <IonAlert
          mode="ios"
          isOpen={isCheckrInvitationError}
          backdropDismiss={true}
          header="Uh oh!"
          message={
            typeof checkrInvitationError?.response?.data === "string"
              ? checkrInvitationError.response.data
              : "We ran into a problem. Please try again, or contact our Support Team if the issue persists."
          }
          buttons={[
            {
              text: "Contact Support",
              handler: handleContactSupport,
            },
            ...(checkrInvitationError?.response?.status &&
            checkrInvitationError.response.status >= 500
              ? [
                  {
                    text: "Try Again",
                    handler: onCheckrInviteClick,
                  },
                ]
              : []),
          ]}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="cbh-logoh-wrapper">
                  <IonImg src="/assets/logo/logo-horizontal.svg" class="logo" />
                </div>
                <div className="form-heading">
                  <h4>Welcome to Clipboard</h4>
                  <p>
                    Congratulations on joining the medical professional marketplace where you can
                    set your own schedule!
                    <br />
                    <br />
                    Here’s what happens next:
                  </p>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <ul className="cbh-welcome-text">
                  {showCheckrBCInvite && (
                    <li>
                      <h4>Background check</h4>
                      <p>
                        Request and complete a background check from our partner service (Checkr)
                      </p>
                      <IonButton
                        expand="block"
                        size="large"
                        class="ion-margin-top ion-margin-bottom continue-button"
                        disabled={isSendingCheckrInvitation || isInvitationSent}
                        onClick={onCheckrInviteClick}
                      >
                        {isSendingCheckrInvitation ? (
                          <IonSpinner name="lines" className="invite-icon" />
                        ) : (
                          <IonIcon icon={mail} className="invite-icon" />
                        )}
                        {isInvitationSent ? "Sent!" : "Email me an invite"}
                      </IonButton>
                    </li>
                  )}
                  <li>
                    <h4>Submitting Requirements</h4>
                    <p>
                      Some facilities may have additional requirements that are unique to them.
                      You’ll see them when you start shopping for shifts!
                    </p>
                  </li>
                  <li>
                    <h4>Start finding shifts</h4>
                    <p>
                      You can immediately start finding shifts that interest you! You’ll be working
                      those shifts and making money in no time!
                    </p>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                disabled={
                  gettingData ||
                  isSendingCheckrInvitation ||
                  (showCheckrBCInvite && !(isCheckrInvitationError || isInvitationSent))
                }
                onClick={onNext}
              >
                Show me some shifts
                {gettingData && <IonSpinner slot="end" class="ion-margin-start" name="lines" />}
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { WelcomeAgent };
